<template>
  <div>
    <div class="card">
      <div class="card-body">
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <h3>Rahmendaten</h3>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  name="company"
                  rules="required"
                  v-slot="{ failed }"
                >
                  <fg-input
                    type="text"
                    label="Firma/Verein"
                    v-model="company"
                    :error="
                      failed ? 'Firma/Verein muss angegeben werden.' : null
                    "
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-3">
                <ValidationProvider
                  name="vaid"
                  rules="required"
                  v-slot="{ failed }"
                >
                  <fg-input
                    type="text"
                    label="Angebots-Nr. (Contao)"
                    v-model="vaId"
                    :error="
                      failed
                        ? 'Die Angebots-Nr. aus Contao muss angegeben werden.'
                        : null
                    "
                    :disabled="vaId != null"
                  >
                  </fg-input>
                </ValidationProvider>
              </div>

              <div class="col-md-3">
                <label>Veranstaltungsort</label>
                <ValidationProvider
                  name="location"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <el-select
                    class="select"
                    placeholder="Ort wählen"
                    filterable
                    v-model="locationID"
                  >
                    <el-option
                      class="select"
                      value="DA"
                      label="Darmstadt"
                      key="DA"
                    ></el-option>
                    <el-option
                      class="select"
                      value="NB"
                      label="Neroberg"
                      key="NB"
                    >
                    </el-option>
                    <el-option
                      class="select" 
                      value="FRA" 
                      label="Frankfurt" 
                      key="FRA">
                    </el-option>
                  </el-select>
                  <span class="invalid mt-2">{{
                    errors[0]
                      ? "Der Ort der Veranstaltung muss ausgewählt werden."
                      : null
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  name="contact"
                  rules="required"
                  v-slot="{ failed }"
                >
                  <fg-input
                    type="text"
                    label="Ansprechpartner"
                    v-model="contact"
                    :error="
                      failed
                        ? 'Ein Ansprechpartner muss angegeben werden.'
                        : null
                    "
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="phone"
                  rules="required"
                  v-slot="{ failed }"
                >
                  <fg-input
                    type="text"
                    label="Telefon"
                    v-model="phone"
                    :error="
                      failed
                        ? 'Eine Telefonnummer des Ansprechpartners muss angegeben werden.'
                        : null
                    "
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label>Datum</label>
                <ValidationProvider
                  name="date"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <el-date-picker
                    v-model="date"
                    type="date"
                    placeholder="Datum wählen"
                    :picker-options="pickerOptions1"
                    format="dd.MM.yyyy"
                  >
                  </el-date-picker>
                  <span class="invalid mt-2">{{
                    errors[0]
                      ? "Das Datum der Veranstaltung muss angegeben werden."
                      : null
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-3">
                <label>Uhrzeit</label>
                <ValidationProvider
                  name="time"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <el-time-select
                    v-model="time"
                    value-format="HH:mm"
                    :picker-options="{
                      start: '08:00',
                      end: '19:00',
                      step: '00:15',
                      format: 'HH:mm',
                    }"
                    placeholder="Uhrzeit wählen"
                  >
                  </el-time-select>
                  <span class="invalid mt-2">{{
                    errors[0]
                      ? "Die Uhrzeit der Veranstaltung muss angegeben werden."
                      : null
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-3">
                <ValidationProvider
                  name="participants"
                  rules="required|min_value:2|max_value:400"
                  v-slot="{ failed }"
                >
                  <fg-input
                    type="number"
                    label="Anzahl Teilnehmer"
                    min="1"
                    max="300"
                    v-model="participants"
                    :error="
                      failed
                        ? 'Die Anzahl der Teilnehmer muss angegeben werden.'
                        : null
                    "
                  >
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-3">
                <label>Sprache</label>
                <ValidationProvider
                  name="language"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <el-select
                    class="select"
                    placeholder="Sprache wählen"
                    filterable
                    v-model="language"
                  >
                    <el-option
                      class="select"
                      value="DE"
                      label="Deutsch"
                      key="DE"
                    ></el-option>
                    <el-option
                      class="select"
                      value="EN"
                      label="Englisch"
                      key="EN"
                    >
                    </el-option>
                  </el-select>
                  <span class="invalid mt-2">{{
                    errors[0]
                      ? "Die Sprache der Veranstaltung muss angegeben werden."
                      : null
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Anmerkungen Büro</label>
                <textarea
                  class="form-control px-2"
                  placeholder="Anmerkungen Büro..."
                  rows="3"
                  v-model="notesOffice"
                ></textarea>
              </div>
            </div>
            <h5 class="pt-4">Trainer</h5>
            <div class="row">
              <div class="col-md-3">
                <label>VA-Trainer</label>
                <el-select
                  class="select"
                  placeholder="Trainer wählen"
                  filterable
                  v-model="vatrainer"
                >
                  <el-option
                    v-for="option in trainer"
                    class="select"
                    :value="option.trainerName"
                    :label="option.trainerName"
                    :key="option.trainerName"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <h3 class="pt-5 mb-2">Ablauf der Veranstaltung</h3>
            <div class="row">
              <div class="col">
                <div class="alert alert-info" role="alert">
                  <b>VA-Trainer: </b>Bitte 1-3 Tage vor eurer Veranstaltung
                  Kontakt mit der betreffenden Ansprechperson aufnehmen und
                  nochmal den Rahmen abklären.
                </div>
              </div>
            </div>
            <h5 class="mt-3">Teilnehmerstruktur</h5>
            <div class="row">
              <div class="col-md-6">
                <el-radio-group v-model="structure1">
                  <el-radio-button
                    label="Teilnehmer kennen sich"
                  ></el-radio-button>
                  <el-radio-button
                    label="Teilnehmer kennen sich nicht"
                  ></el-radio-button>
                </el-radio-group>
              </div>
              <div class="col-md-6">
                <el-radio-group v-model="structure2">
                  <el-radio-button label="Homogene Gruppe"></el-radio-button>
                  <el-radio-button label="Heterogene Gruppe"></el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <div class="row pb-4 mt-3">
              <div class="col-md-6">
                <label>Besonderheiten & Anmerkungen</label>
                <textarea
                  class="form-control px-2"
                  placeholder="Anmerkungen zur Teilnehmerstruktur..."
                  rows="3"
                  v-model="structureNote"
                ></textarea>
              </div>
            </div>
            <h5 class="mt-3">Ziel der Veranstaltung</h5>
            <div class="row">
              <div class="col-md-4">
                <p-checkbox v-model="targetFun"
                  >Spaß, gemeinsames Erlebnis</p-checkbox
                >
                <p-checkbox v-model="targetTrust"
                  >Zusammenführen, Vertrauen fördern</p-checkbox
                >
              </div>
              <div class="col-md-4">
                <p-checkbox v-model="targetEdu"
                  >Spielerisch schulen (Themen wie Kommunikation
                  usw.)</p-checkbox
                >
                <p-checkbox v-model="targetMisc">Sonstiges</p-checkbox>
              </div>
              <div class="col-md-4">
                <textarea
                  class="form-control px-2"
                  placeholder="Ziel beschreiben..."
                  rows="2"
                  v-model="targetNote"
                  :disabled="targetMisc == false"
                ></textarea>
              </div>
            </div>

            <h5 class="mt-4">Alternativprogramm</h5>
            <div class="row">
              <div class="col-md-2">
                <p-checkbox v-model="alternativePfadfinder"
                  >Pfadfinder</p-checkbox
                >
              </div>
              <div class="col-md-2">
                <p-checkbox
                  v-model="alternativeNero"
                  :disabled="locationID == 'DA'"
                  >Nerobergführung</p-checkbox
                >
              </div>
              <div class="col-md-2">
                <p-checkbox
                  v-model="alternativeVivarium"
                  :disabled="locationID == 'NB'"
                  >Vivarium</p-checkbox
                >
              </div>
            </div>
            <h5 class="mt-4">Extras</h5>
            <div class="row">
              <div class="col-md-2">
                <p-checkbox v-model="extraBahn" :disabled="locationID == 'DA'"
                  >Nerobergbahn</p-checkbox
                >
              </div>
              <div class="col-md-2">
                <p-checkbox v-model="extraDrinks">Getränke</p-checkbox>
              </div>
              <div class="col-md-2">
                <p-checkbox v-model="extraSnacks">Schokoriegel</p-checkbox>
              </div>
              <div class="col-md-2">
                <p-checkbox
                  v-model="extraCatering"
                  :disabled="locationID == 'NB'"
                  >Catering (über Jameel)</p-checkbox
                >
              </div>
            </div>
            <h5 class="mt-4">Welche Teamspiele sind bereits bekannt?</h5>
            <div class="row mb-2">
              <div class="col-md-6">
                <textarea
                  class="form-control px-2"
                  placeholder="Der Gruppe bereits bekannte Teamspiele..."
                  rows="2"
                  v-model="bekannteTeamspiele"
                ></textarea>
              </div>
            </div>
            <h5 class="mt-4">Sonstige Anmerkungen</h5>
            <div class="row mb-2">
              <div class="col-md-6">
                <textarea
                  class="form-control px-2"
                  placeholder="Sonstige Anmerkungen..."
                  rows="4"
                  v-model="notes"
                ></textarea>
              </div>
            </div>

            <div class="text-right">
              <button
                type="reset"
                class="btn btn-danger btn-fill btn-wd"
                @click.prevent="onCancel"
              >
                Abbrechen
              </button>
              <button
                type="submit"
                class="btn btn-success btn-fill btn-wd"
                @click.prevent="validate()"
              >
                Speichern
              </button>
            </div>
            <div class="clearfix"></div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import PSwitch from "src/components/UIComponents/Switch.vue";
import router from "../../../../main";
import {
  Input,
  Select,
  Option,
  TimeSelect,
  TimePicker,
  MessageBox,
} from "element-ui";
import { extend } from "vee-validate";
import { required, min_value, max_value } from "vee-validate/dist/rules";
extend("required", required);
extend("min_value", min_value);
extend("max_value", max_value);
export default {
  components: {
    PSwitch,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [TimeSelect.name]: TimeSelect,
    MessageBox,
  },

  data() {
    return {
      vaId: null,
      company: null,
      locationID: null,
      contact: null,
      phone: null,
      date: null,
      time: null,
      participants: null,
      language: "DE",
      vatrainer: null,
      trainer: [],
      structure1: null,
      structure2: null,
      structureNote: null,
      targetFun: false,
      targetTrust: false,
      targetEdu: false,
      targetMisc: false,
      targetNote: null,
      alternativePfadfinder: false,
      alternativeNero: false,
      alternativeVivarium: false,
      extraBahn: false,
      extraDrinks: false,
      extraSnacks: false,
      extraCatering: false,
      notes: null,
      notesOffice: null,
      bekannteTeamspiele: null,
      saved: false,

      pickerOptions1: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Heute",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
        ],
      },
    };
  },
  created() {
    this.vaId = this.$route.params.vaId || null;

    // get list of trainers
    const trainerRef = db.collection("trainer");
    trainerRef
      .where("isVA", "==", true)
      .orderBy("Name")
      .onSnapshot((snapshotChange) => {
        this.trainer = [];
        snapshotChange.forEach((doc) => {
          this.trainer.push({
            trainerName: doc.data().Name,
          });
        });
      });

    if (this.vaId != null) {
      // get data of VA
      this.loadVA(this.vaId);
    }
  },
  methods: {
    loadVA(id) {
      const veranstaltungenRef = db.collection("veranstaltungen");
      veranstaltungenRef
        .doc(this.vaId)
        .get()
        .then((doc) => {
          (this.company = doc.data().company),
            (this.locationID = doc.data().locationID),
            (this.contact = doc.data().contact),
            (this.phone = doc.data().phone),
            (this.date = new Date(doc.data().date.seconds * 1000)),
            (this.time = doc.data().time),
            (this.participants = doc.data().participants),
            (this.language = doc.data().language),
            (this.vatrainer = doc.data().vatrainer || null),
            (this.structure1 = doc.data().structure1 || null),
            (this.structure2 = doc.data().structure2 || null),
            (this.structureNote = doc.data().structureNote || null),
            (this.targetFun = doc.data().targetFun || false),
            (this.targetTrust = doc.data().targetTrust || false),
            (this.targetEdu = doc.data().targetEdu || false),
            (this.targetMisc = doc.data().targetMisc || false),
            (this.targetNote = doc.data().targetNote || null),
            (this.alternativePfadfinder =
              doc.data().alternativePfadfinder || false),
            (this.alternativeNero = doc.data().alternativeNero || false),
            (this.alternativeVivarium =
              doc.data().alternativeVivarium || false),
            (this.extraBahn = doc.data().extraBahn || false),
            (this.extraDrinks = doc.data().extraDrinks || false),
            (this.extraSnacks = doc.data().extraSnacks || false),
            (this.extraCatering = doc.data().extraCatering || false),
            (this.notes = doc.data().notes || null),
            (this.notesOffice = doc.data().notesOffice || null),
            (this.bekannteTeamspiele = doc.data().bekannteTeamspiele || null);
        });
    },
    onCancel() {
      router.push({ name: "Veranstaltungen" });
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        if (res) {
          this.updateVA();
        }
      });
    },
    updateVA() {
      const veranstaltungRef = db.collection("veranstaltungen");
      veranstaltungRef
        .doc(this.vaId)
        .set({
          company: this.company,
          locationID: this.locationID,
          contact: this.contact,
          phone: this.phone,
          date: this.date,
          time: this.time,
          participants: parseInt(this.participants),
          language: this.language,
          vatrainer: this.vatrainer,
          structure1: this.structure1,
          structure2: this.structure2,
          structureNote: this.structureNote,
          targetFun: this.targetFun,
          targetTrust: this.targetTrust,
          targetEdu: this.targetEdu,
          targetMisc: this.targetMisc,
          targetNote: this.targetNote,
          alternativePfadfinder: this.alternativePfadfinder,
          alternativeNero: this.alternativeNero,
          alternativeVivarium: this.alternativeVivarium,
          extraBahn: this.extraBahn,
          extraDrinks: this.extraDrinks,
          extraSnacks: this.extraSnacks,
          extraCatering: this.extraCatering,
          notes: this.notes,
          notesOffice: this.notesOffice,
          bekannteTeamspiele: this.bekannteTeamspiele,
          lastUpdate: new Date(),
          removed: false,
        })
        .then(() => {
          this.saved = true;
          router.push({ name: "Veranstaltungen" });
        })
        .catch((error) => {
          console.log("Fehler beim Speichern der Veranstaltung: " + error);
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.saved) {
      MessageBox.confirm(
        "Änderungen wurden noch nicht gespeichert. Alle Änderungen gehen verloren. Wirklich verlassen?",
        "Warnung",
        {
          confirmButtonText: "Verlassen",
          cancelButtonText: "Abbrechen",
          type: "warning",
          callback: (action) => {
            if (action == "confirm") {
              next();
            } else {
              next(false);
            }
          },
        }
      );
    } else {
      next();
    }
  },
};
</script>
<style>
.invalid {
  width: 100%;
  margin-top: 0rem !important;
  font-size: 80%;
  color: #dc3545;
}
</style>
