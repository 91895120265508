export default [
  {
    name: 'Übersicht',
    icon: 'nc-icon nc-bulb-63',
    path: '/ket/overview'
  },
  {
    name: 'Kletterwald',
    icon: 'nc-icon nc-circle-10',
    children: [
      {
        name: 'Anmerkungen',
        path: '/ht/anmerkungen'
      },
      {
        name: 'Checkliste HT Start',
        path: '/checklist/HTStart'
      },
      {
        name: 'Checkliste HT Ende',
        path: '/checklist/HTEnde'
      },
      {
        name: 'Retterstatus',
        path: '/ht/retterstatus'
      },
      {
        name: 'Tagesprotokoll',
        path: '/ht/tagesprotokoll'
      },
      {
        name: 'Kontaktliste',
        path: '/ht/kontaktliste'
      },
      {
        name: 'Abseil-Geräte',
        path: '/ht/hubgeraete'
      },
      {
        name: 'Unfallprotokoll',
        path: '/print/unfallprotokoll'
      },
      {
        name: 'Buschtrommel',
        path: '/ht/buschtrommel'
      },
      {
        name: 'Betriebsordner',
        path: '/ht/betriebsordner'
      },
      {
        name: 'Trainerschlüssel',
        path: '/ht/reportrate'
      },
      {
        name: 'Projekte',
        path: '/ht/projekte'
      }
    ]
  },
  {
    name: 'Kasse',
    icon: 'nc-icon nc-bank',
    children: [
      {
        name: 'Checkliste Kasse Start',
        path: '/checklist/KasseStart'
      },
      {
        name: 'Checkliste Kasse Ende',
        path: '/checklist/KasseEnde'
      },
      {
        name: 'Basis/Premium Pakete',
        path: '/specials'
      },
      {
        name: 'Rabattliste',
        path: '/rabattliste'
      },
      {
        name: 'Kassenstart',
        path: '/kassenstart'
      },
      {
        name: 'Kassenabschluss',
        path: '/kassenabschluss'
      },
      {
        name: 'Hüttencheckliste',
        path: '/checklist/Huette'
      },
      {
        name: 'Kassen-Manual',
        path: '/print/Kassen-Manual'
      },
      {
        name: 'Liste Kinderprogramme',
        path: '/print/kinderprogramme'
      }
    ]
  },
 /* {
    name: 'PSA',
    icon: 'nc-icon nc-settings',
    children: [
      {
        name: 'Smartbelay-Sets',
        path: '/psa/smarties'
      }
    ]
  }, */
  {
    name: 'Veranstaltungen',
    icon: 'nc-icon nc-spaceship',
    children: [
      {
        name: 'Veranstaltungen',
        path: '/va/veranstaltungen'
      },
      {
        name: 'Checkliste Start',
        path: '/checklist/VAStart'
      },
      {
        name: 'Checkliste Ende',
        path: '/checklist/VAEnde'
      }
    ]
  },
  {
    name: 'Parkleitung/Büro',
    icon: 'nc-icon nc-laptop',
    children: [
      {
        name: 'Tagesreport',
        path: '/pl/tagesreport'
      },
      {
        name: 'Trainer',
        path: '/pl/trainer'
      },
      {
        name: 'Bankeinzahlungen',
        path: '/pl/bankeinzahlungen'
      },
      {
        name: 'Toilettenreinigung',
        path: '/pl/toilettenliste'
      },
      {
        name: 'Checklisten Büro',
        path: '/checklist/Buero-weekly'
      },
      {
        name: 'Verleih-Liste',
        path: '/pl/verleihliste'
      },
      {
        name: 'Checklisten verwalten',
        path: '/pl/managelists'
      },
      {
        name: 'Rabatte verwalten',
        path: '/pl/rabatte'
      },
      {
        name: 'Parcours verwalten',
        path: '/pl/parcours'
      },
      {
        name: 'Benutzer verwalten',
        path: '/pl/users'
      }
    ]
  },
  {
    name: 'Trainer',
    icon: 'nc-icon nc-circle-10',
    children: [
      {
        name: 'Kontaktliste',
        path: '/ht/kontaktliste'
      },
      {
        name: 'Retterstatus',
        path: '/ht/retterstatus'
      },
      {
        name: 'Betriebsordner',
        path: '/ht/betriebsordner'
      },
      {
        name: 'Buschtrommel',
        path: '/ht/buschtrommel'
      },
      {
        name: 'Veranstaltungen',
        path: '/va/veranstaltungen'
      },
    ]
  },
]
