<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-8">
            <h5 class="card-title">Parcours verwalten</h5>
          </div>
          <div class="col-sm-4 text-right">
            <div class="row">
              <div class="col-md-6">
            </div>
              <div class="col-md-6" v-if="role == 3">
            <div>
              <el-select
                v-model="location"
                placeholder="Standort wählen"
                @change="loadParcours"
              >
              <el-option
                key="NB"
                label="Neroberg"
                value="NB"
              ></el-option>
              <el-option
                key="DA"
                label="Darmstadt"
                value="DA"
              ></el-option>
              <el-option
                key="FRA"
                label="Frankfurt"
                value="FRA"
              ></el-option>
              </el-select>
            </div>
            </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="parcours"
            header-row-class-name="text-primary"
            empty-text="Es wurden keine Parcours gefunden."
          >
            <el-table-column prop="parcoursPosition" label="#" width="100">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  type="number"
                  v-model="scope.row.parcoursPosition"
                  controls-position="right"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="parcoursName" label="Parcours" width="500">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  style="text-align: center"
                  v-model="scope.row.parcoursName"
                  controls-position="right"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="parcoursCategorie"
              label="Kategorie"
              width="200"
            >
              <template slot-scope="scope">
                <el-select v-model="scope.row.parcoursCategorie">
                  <el-option value="1" label="Grün" key="1"></el-option>
                  <el-option value="2" label="Blau" key="2"></el-option>
                  <el-option value="3" label="Rot" key="3"></el-option>
                  <el-option value="4" label="Grau" key="4"></el-option>
                  <el-option value="5" label="Schwarz" key="5"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="parcoursHeight"
              label="Höhe (m)"
              width="200"
            ><template slot-scope="scope">
                <el-input-number
                  size="small"
                  style="text-align: center"
                  v-model="scope.row.parcoursHeight"
                  :min="0"
                  :max="25"
                ></el-input-number>
              </template></el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label=""
            >
              <template slot-scope="props">
                <el-tooltip content="Änderungen speichern" placement="top">
                  <p-button
                    type="success"
                    size="sm"
                    icon
                    @click="editParcours(props.$index, props.row)"
                  >
                    <i class="fa fa-save"></i>
                  </p-button>
                </el-tooltip>
                <el-tooltip content="Parcours löschen" placement="top">
                  <p-button
                    type="danger"
                    size="sm"
                    icon
                    @click="removeParcours(props.$index, props.row)"
                  >
                    <i class="fa fa-trash"></i>
                  </p-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div class="col-md-6 pt-4">
            <card>
              <h6 slot="header" class="card-title">Parcours hinzufügen</h6>
              <div class="row">
                <div class="col-sm-8">
                  <fg-input
                    placeholder="Name des Parcours"
                    label="Parcours"
                    v-model="newParcours"
                  ></fg-input>
                </div>
                <div class="col">
                  <fg-input
                    label="Höhe (m)"
                    v-model="newHeight"
                    type="number"
                    min="0"
                    max="25"
                  ></fg-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <p-radio label="1" v-model="newCategorie" :inline="true"
                    >Grün</p-radio
                  >
                  <p-radio label="2" v-model="newCategorie" :inline="true"
                    >Blau</p-radio
                  >
                  <p-radio label="3" v-model="newCategorie" :inline="true"
                    >Rot</p-radio
                  >
                  <p-radio label="4" v-model="newCategorie" :inline="true"
                    >Grau</p-radio
                  >
                  <p-radio label="5" v-model="newCategorie" :inline="true"
                    >Schwarz</p-radio
                  >
                </div>
              </div>
              <div class="row text-right">
                <div class="col">
                  <p-button
                    type="success"
                    :disabled="newParcours == null || newCategorie == null || newHeight == 0"
                    @click="addParcours()"
                    >Hinzufügen</p-button
                  >
                </div>
              </div>
            </card>
            <!-- end card -->
          </div>
          <!--  end col-md-6  -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db } from "../../../../firebaseDb";
import { Table, TableColumn, Tooltip, Select, Option, Input, InputNumber } from "element-ui";
import store from "../../../../store";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
  },

  data() {
    return {
      parcours: [],
      newParcours: null,
      newCategorie: null,
      newPos: 0,
      newHeight: 0,
      location: store.getters.userlocation,
      role: store.getters.userrole
    };
  },
  created() {
    this.loadParcours();
  },
  methods: {
    loadParcours() {
      const parcoursRef = db.collection("parcours");
      parcoursRef.where('locationID', '==', this.location).orderBy("position").onSnapshot((snapshotChange) => {
        this.parcours = [];
        snapshotChange.forEach((doc) => {
          this.parcours.push({
            key: doc.id,
            parcoursName: doc.data().Name,
            parcoursCategorie: doc.data().categorie,
            parcoursPosition: doc.data().position,
            parcoursHeight: doc.data().height || 0,
          });
        });
      });
    },
    removeParcours(index, row) {
      db.collection("parcours")
        .doc(row.key)
        .delete()
        .then(() => {
          console.log("Parcours " + row.parcoursName + " removed.");
        })
        .catch(() => {
          console.log("Error removing parcours.");
        });
    },
    addParcours() {
      this.newPos =
        Math.max.apply(
          Math,
          this.parcours.map(function (obj) {
            return obj.parcoursPosition;
          })
        ) + 1;
      if (this.newPos === -Infinity) {
        this.newPos = 1;
      }
      db.collection("parcours")
        .add({
          Name: this.newParcours,
          categorie: this.newCategorie,
          position: this.newPos,
          locationID: this.location,
          height: this.newHeight
        })
        .then(() => {
          this.newParcours = null;
          this.newCategorie = null;
          this.newPos = 0;
          this.newHeight = 0;
          console.log("Parcours added.");
        })
        .catch(() => {
          console.log("Adding parcours failed");
        });
    },
    editParcours(index, row) {
      db.collection("parcours")
        .doc(row.key)
        .update({
          Name: row.parcoursName,
          categorie: row.parcoursCategorie,
          position: parseInt(row.parcoursPosition),
          height: parseInt(row.parcoursHeight),
        })
        .then(() => {
          console.log("Parcours updated.");
        })
        .catch((error) => {
          console.log("Error updating parcours " + error);
        });
    },
  },
};
</script>
<style>
</style>
